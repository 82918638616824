<template>
  <div class="backoffice_general-plugins">
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('backoffice.plugins.general_handled_plugins.alert')"></span>
    </el-alert>
    <base-section>
      <el-form label-position="top" inline @submit.prevent>
        <base-select :items="statuses" v-model="status" filterable />
        <div class="plugin-filter">
          <base-input
            v-model="userSearch"
            :label="$t('backoffice.plugins.user_search')"
            :placeholder="$t('dashboard.search_bar.placeholder')"
            prop="userSearch"
            name="userSearch"
            class="user-search"
          >
            <template #prependInput><i class="ri-search-line"></i></template>
          </base-input>
          <base-datepicker
            v-model="purchaseCreatedOnFrom"
            name="purchaseCreatedOnFrom"
            :clearable="true"
            :label="$t('backoffice.plugins.created_on_from')"
            :max-width="200"
          />
          <base-datepicker
            v-model="purchaseCreatedOnTo"
            name="purchaseCreatedOnTo"
            :clearable="true"
            :label="$t('backoffice.plugins.created_on_to')"
            :max-width="200"
          />
        </div>
      </el-form>
    </base-section>

    <el-table
      id="ordersTable"
      ref="table"
      :data="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="defaultSort"
      row-class-name="table-row"
      @sort-change="sortChange"
      style="width: 100%"
    >
      <el-table-column prop="icon" width="37">
        <template>
          <i class="ri-contacts-line"></i>
        </template>
      </el-table-column>

      <el-table-column
        class-name="text-bold"
        prop="userName"
        :label="$t('general.label.user')"
        min-width="180"
        :show-overflow-tooltip="true"
        sortable="sortColumn"
      >
        <template slot-scope="{ row }">
          <a href="#" @click.prevent="showUserDetails(row)">{{ row.data.userName }}</a>
        </template>
      </el-table-column>

      <el-table-column
        prop="serviceResourceOspId"
        :label="$t('general.label.service')"
        :show-overflow-tooltip="true"
        min-width="110"
      >
        <template slot-scope="{ row }">{{ row.data.serviceResourceOspId }}</template>
      </el-table-column>

      <el-table-column
        prop="userLogin"
        :label="$t('general.label.email')"
        min-width="170"
        :show-overflow-tooltip="true"
        sortable="sortColumn"
      >
        <template v-if="row.data.purchaseSource === 'EMAIL'" slot-scope="{ row }">
          <a href="#" @click.prevent="getEmail(row)">{{ $t('plugins.email_label.view_email') }}</a>
        </template>
      </el-table-column>

      <el-table-column
        class-name="actions-cell justify-between"
        prop="createdOn"
        :label="$t('general.label.added_on')"
        :show-overflow-tooltip="true"
        width="220"
        sortable="sortColumn"
      >
        <template slot-scope="{ row }">{{ formatDate(row.data.createdOn) }}</template>
      </el-table-column>
      <el-table-column
        class-name="actions-cell justify-between"
        prop="purchaseStatus"
        :label="$t('general.label.status')"
        :show-overflow-tooltip="true"
        width="220"
        sortable="sortColumn"
      >
        <template slot-scope="{ row }">{{ row.data.purchaseStatus }}</template>
      </el-table-column>

      <el-table-column
        class-name="text-bold"
        prop="userOspId"
        :label="$t('general.label.ca')"
        :show-overflow-tooltip="true"
        min-width="150"
      >
        <template slot-scope="{ row }">{{ row.data.userOspId }}</template>
      </el-table-column>

      <el-table-column
        prop="partner"
        :label="$t('general.label.partner')"
        :show-overflow-tooltip="true"
        min-width="150"
      >
        <template slot-scope="{ row }">{{ row.data.partner ? row.data.partner : '-' }}</template>
      </el-table-column>
    </el-table>
    <view-email-modal
      :isVisible="isEmailModalVisible"
      :emailData="getEmailData"
      :close="closeEmailModal"
    />
  </div>
</template>

<script>
import Plugins from '@/mixins/Plugins'
import TableLoader from '@/mixins/TableLoader'

import ViewEmailModal from '@/components/Backoffice/Plugins/ViewEmailModal'

export default {
  name: 'HandledPlugins',
  mixins: [Plugins, TableLoader],
  components: { ViewEmailModal },
  data () {
    return {
      statuses: [
        { value: 'REJECTED' },
        { value: 'ACCEPTED' },
        { value: 'DECLINED' },
        { value: 'REQUESTED' }
      ],
      status: 'REJECTED'
    }
  },
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.plugin-filter {
  margin-top: 16px;
  display: flex;
  gap: 16px;
}
.user-search {
  width: 300px;
}
</style>
