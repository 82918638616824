<template>
  <el-dialog
    :title="$t('backoffice.orders.general_orders.emailModal.title')"
    :visible.sync="isVisible"
    id="pluginEmailModal"
    @close="close">
    <base-section>
      <p><span class="text-muted">{{ $t('backoffice.orders.general_orders.emailModal.from') }}:</span> <span v-html="emailData.from"></span></p>
    </base-section>
    <base-section>
      <p><span class="text-muted">{{ $t('backoffice.orders.general_orders.emailModal.subject') }}:</span> <span class="text-bold" v-html="emailData.subject"></span></p>
    </base-section>
    <!--    <div>Subject: <span v-html="emailData.subject"></span></div>-->
    <base-section>
      <div v-html="emailData.content"/>
    </base-section>
    <base-section v-if="attachments && attachments.length">
      <p><span class="text-muted">{{ $t('backoffice.orders.general_orders.emailModal.attachments') }}:</span></p>
      <ul>
        <li v-for="(attachment, index) in attachments" :key="index">
          <a :href="`data:${attachment.mimeType};base64,${attachment.fileContentBase64}`" :download="attachment.fileName">{{ attachment.fileName }}</a>
        </li>
      </ul>
    </base-section>
  </el-dialog>
</template>

<script>
import { get } from 'lodash'
// import { uploadFile } from '../../../app/helpers'

export default {
  name: 'ViewEmailModal',
  props: {
    emailData: {
      type: [Object, String, null],
      default: () => {
      }
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    close: {
      type: Function,
      default: () => {
      }
    }
  },
  computed: {
    attachments () {
      return get(this.emailData, 'attachments', [])
    }
  },
  methods: {
    getAttachment (attachment) {
      const a = document.createElement('a')
      a.href = `data:${attachment.mimeType};base64,${attachment.fileContentBase64}`
      a.download = attachment.fileName
      a.click()
    }
  }
}
</script>
