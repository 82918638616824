<template>
  <page-layout
    :title="$t('backoffice.orders.title')"
    :sub-title="subtitle"
    :fixed-footer="!!totalPages && totalPages > 1"
  >
    <!--    <template #header-actions>-->
    <!--      <base-button-->
    <!--        v-if="showSaveDetailsButton"-->
    <!--        :key="key"-->
    <!--        :label="$t('general.button_label.save_changes')"-->
    <!--        type="primary"-->
    <!--        :loading="$refs.routerView.saving"-->
    <!--        :disabled="!$refs.routerView.dirty"-->
    <!--        @click="$refs.routerView.handleSaveChanges"-->
    <!--      />-->
    <!--    </template>-->
    <el-tabs :value="activeName" ref="tabs">
      <el-tab-pane v-for="tab in tabs" :key="tab.name" :name="tab.name">
        <span slot="label" class="flex-span"><i v-if="tab.icon" :class="tab.icon"></i> {{ tab.label }}</span>
      </el-tab-pane>
    </el-tabs>
    <router-view ref="routerView" :key="$route.name" :totalPages.sync="totalPages" :pagination.sync="pagination"/>
    <template #footer v-if="totalPages && totalPages > 1">
      <base-pagination
        :key="'pagination_' + $route.name"
        v-model="pagination.page"
        :pages="totalPages"
      />
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import { mapGetters } from 'vuex'
import TabNavigation from '../mixins/TabNavigation'

export default {
  name: 'BackofficeOrdersLayout',
  components: {
    PageLayout
  },
  mixins: [TabNavigation],
  props: {
    emitBack: {
      typ: Boolean,
      default: false
    }
  },
  data () {
    return {
      key: '',
      activeName: '',
      totalPages: 0,
      pagination: {
        page: 0,
        size: 15,
        sort: {}
      }
    }
  },
  methods: {},
  watch: {
    '$route.name' () {
      this.totalPages = 0
      if (this.$refs?.routerView?.pagination) {
        this.$refs.routerView.pagination.page = 0
      }
    }
  },
  computed: {
    ...mapGetters({}),
    subtitle () {
      switch (this.$route.name) {
        case 'BackofficeOrdersGeneral':
          return this.$t('backoffice.orders.tabs.general_orders')
        case 'BackofficePluginService':
          return this.$t('backoffice.orders.tabs.plugin_service')
        case 'BackofficeHandledOrders':
          return this.$t('backoffice.orders.tabs.handled_orders')
        case 'BackofficeHandledPlugins':
          return this.$t('backoffice.orders.tabs.handled_plugins')
      }
      return 'General orders'
    },
    tabs () {
      return [
        {
          label: this.$t('backoffice.orders.tabs.general_orders'),
          name: 'BackofficeOrdersGeneral',
          icon: 'ri-building-4-line'
        },
        {
          label: this.$t('backoffice.orders.tabs.plugin_service'),
          name: 'BackofficePluginService',
          icon: 'ri-ticket-line'
        },
        {
          label: this.$t('backoffice.orders.tabs.handled_orders'),
          name: 'BackofficeHandledOrders',
          icon: 'ri-check-line'
        },
        {
          label: this.$t('backoffice.orders.tabs.handled_plugins'),
          name: 'BackofficeHandledPlugins',
          icon: 'ri-check-line'
        }
      ]
    }
  }
}
</script>

<style lang="scss">

</style>
