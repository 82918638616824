import api from '@/api'

import Repository from './Repository'

class PluginServiceRepository extends Repository {
  getPlugins (form, options) {
    return api.plugin.getByFilter(form, options)
  }

  rejectPlugin (purchaseUUID, oprions) {
    return api.plugin.rejectPlugin({ purchaseUUID }, oprions)
  }

  updatePlugin (form, oprions) {
    return api.plugin.updatePlugin(form, oprions)
  }

  getEmail (form, oprions) {
    return api.plugin.getEmail(form, oprions)
  }

  aprovePlugin (form, options) {
    return api.plugin.aprovePlugin(form, options)
  }
}

export default PluginServiceRepository
