<template>
  <div class="backoffice_general-plugins">
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('backoffice.plugins.general_plugins.alert')"></span>
    </el-alert>

    <el-form label-position="top" class="plugin-filter" @submit.prevent>
      <base-input
        v-model="userSearch"
        :label="$t('backoffice.plugins.user_search')"
        :placeholder="$t('dashboard.search_bar.placeholder')"
        prop="userSearch"
        name="userSearch"
        class="user-search"
      >
        <template #prependInput><i class="ri-search-line"></i></template>
      </base-input>
      <base-datepicker
        v-model="purchaseCreatedOnFrom"
        name="purchaseCreatedOnFrom"
        :clearable="true"
        :label="$t('backoffice.plugins.created_on_from')"
        :max-width="200"
      />
      <base-datepicker
        v-model="purchaseCreatedOnTo"
        name="purchaseCreatedOnTo"
        :clearable="true"
        :label="$t('backoffice.plugins.created_on_to')"
        :max-width="200"
      />
    </el-form>

    <el-table
      id="ordersTable"
      ref="table"
      :data="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="defaultSort"
      row-class-name="table-row"
      style="width: 100%"
      @sort-change="sortChange"
    >
      <el-table-column prop="icon" width="37">
        <template>
          <i class="ri-contacts-line"></i>
        </template>
      </el-table-column>

      <el-table-column
        class-name="text-bold"
        prop="userName"
        :label="$t('general.label.user')"
        min-width="180"
        :show-overflow-tooltip="true"
        sortable="custom"
        :sort-orders="sortOrders"
      >
        <template slot-scope="{ row }">
          <a href="#" @click.prevent="showUserDetails(row)">{{ row.data.userName }}</a>
        </template>
      </el-table-column>

      <el-table-column
        prop="serviceResourceOspId"
        :label="$t('general.label.service')"
        :show-overflow-tooltip="true"
        min-width="110"
      >
        <template slot-scope="{ row }">{{ row.data.serviceResourceOspId }}</template>
      </el-table-column>

      <el-table-column
        prop="userLogin"
        :label="$t('general.label.email')"
        min-width="170"
        :show-overflow-tooltip="true"
        sortable="custom"
        :sort-orders="sortOrders"
      >
        <template v-if="row.data.purchaseSource === 'EMAIL'" slot-scope="{ row }">
          <a href="#" @click.prevent="handleConfirm(row)">{{
            $t('plugins.email_label.view_email')
          }}</a>
        </template>
      </el-table-column>

      <el-table-column
        class-name="actions-cell justify-between"
        prop="createdOn"
        :label="$t('general.label.added_on')"
        :show-overflow-tooltip="true"
        width="220"
        :sort-orders="sortOrders"
        sortable="custom"
      >
        <template slot-scope="{ row }">{{ formatDate(row.data.createdOn) }}</template>
      </el-table-column>

      <el-table-column
        class-name="text-bold"
        prop="userOspId"
        :label="$t('general.label.ca')"
        :show-overflow-tooltip="true"
        min-width="150"
      >
        <template slot-scope="{ row }">{{ row.data.userOspId }}</template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="partner"
        :label="$t('general.label.partner')"
        :show-overflow-tooltip="true"
        min-width="150"
      >
        <template slot-scope="{ row }">{{ row.data.partner ? row.data.partner : '-' }}</template>
      </el-table-column>

      <el-table-column class-name="actions-cell" prop="buttons" :label="''" width="150">
        <template slot-scope="{ row }">
          <base-button
            :label="$t('general.label.process')"
            type="primary"
            size="small"
            @click.stop="handleConfirm(row)"
          ></base-button>

          <base-button
            @click.stop="handleDelete(row)"
            size="small"
            prepend-icon="ri-delete-bin-2-line"
          ></base-button>
        </template>
      </el-table-column>
    </el-table>

    <general-plugin-confirmation
      :emailData="getEmailData"
      ref="pluginConfirmation"
      @confirm="confirmUpdate"
      @delete="handleDelete"
      @close="closeEmailModal"
    />

    <general-plugin-cancel ref="pluginCancel" @confirm="confirmCancel" />
  </div>
</template>

<script>
import GeneralPluginConfirmation from '@/components/Backoffice/Plugins/GeneralPluginConfirmation'
import GeneralPluginCancel from '@/components/Backoffice/Plugins/GeneralPluginCancel'
import Plugins from '@/mixins/Plugins'
import PluginServiceRepository from '@/app/repositories/PluginServiceRepository'

const pluginService = new PluginServiceRepository()

export default {
  name: 'PluginService',
  mixins: [Plugins],
  components: {
    GeneralPluginConfirmation,
    GeneralPluginCancel
  },
  methods: {
    // sortChange (sort) {
    //   // console.log(sort)
    //   this.pagination.sort.sortOrders = [
    //     {
    //       direction: sort.order.toUpperCase(),
    //       property: sort.prop
    //     }
    //   ]
    //   return this.fetchPlugins()
    // },
    async confirmUpdate (form) {
      this.$refs.pluginConfirmation.setLoading(true)
      try {
        await pluginService.updatePlugin(form)
        await pluginService.aprovePlugin(
          {
            overrideLimits: form.overrideLimits,
            purchaseUUID: form.purchaseUuid
          },
          {
            successNotification: this.$t('plugin.update.notification')
          }
        )
        this.$refs.pluginConfirmation.close()
        this.fetchPlugins()
      } finally {
        this.$refs.pluginConfirmation.setLoading(false)
      }
    },
    confirmCancel (id) {
      pluginService.rejectPlugin(id, {
        successNotification: this.$t('plugin.reject.notification'),
        onSuccess: () => {
          this.$refs.pluginConfirmation.close()
          return this.fetchPlugins()
        }
      })
    },
    handleConfirm (plugin) {
      this.getEmailWithoutModal(plugin)
      this.$refs.pluginConfirmation.show(plugin)
    },
    handleDelete (plugin) {
      this.$refs.pluginCancel.show(plugin)
    }
  }
}
</script>

<style lang="scss" scoped>
.plugin-filter {
  margin-top: 16px;
  display: flex;
  gap: 16px;
}
.user-search {
  width: 300px;
  :deep(.el-form-item__label) {
    margin-bottom: 4px;
  }
}
</style>
