<template>
  <el-dialog
    :title="$t('backoffice.orders.general_orders.confirmation.title')"
    :visible.sync="dialogFormVisible"
    @close="close"
  >
    <el-form v-if="order" :key="dialogKey" :model="form" label-position="top">
      <base-input
        ref="userName"
        prop="userName"
        :value="order.userName"
        name="userName"
        :disabled="true"
        auto-complete="off"
        :label="$t('general.label.user')"
      ></base-input>
      <base-input
        ref="action"
        prop="action"
        :value="order.action"
        name="action"
        :disabled="true"
        auto-complete="off"
        :label="$t('general.label.action')"
      ></base-input>
      <template v-if="this.mivbStibMobib">
        <base-input
          ref="mobibCardNumber"
          prop="mobibCardNumber"
          v-model="form.mobibCardNumber"
          name="mobibCardNumber"
          auto-complete="off"
          :required="true"
          :label="$t('general.label.mobib_card_number')"
          :validations="$v.form.mobibCardNumber"
        ></base-input>
      </template>
      <template v-else-if="this.enecoCard">
        <base-input
          ref="cardNumber"
          prop="cardNumber"
          v-model="form.cardNumber"
          name="cardNumber"
          auto-complete="off"
          :required="true"
          :label="$t('general.label.card_number')"
          :validations="$v.form.cardNumber"
        ></base-input>
      </template>
      <template v-else-if="this.veloSeason">
        <base-datepicker
          :label="$t('general.label.start_date')"
          :required="true"
          v-model="form.startDate"
          :clearable="true"
          :validations="$v.form.startDate"
        />
        <base-input
          ref="accountId"
          prop="accountId"
          v-model="form.accountId"
          name="accountId"
          auto-complete="off"
          :required="true"
          :label="$t('general.label.account_id')"
          :validations="$v.form.accountId"
        ></base-input>
      </template>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <base-button :label="$t('general.cancel')" @click="close"></base-button>
      <base-button
        :loading="loading"
        type="primary"
        :label="$t('general.button_label.save_changes')"
        @click="handleConfirm"
      ></base-button>
    </span>
  </el-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'GeneralOrderConfirmation',
  data () {
    return {
      order: null,
      loading: false,
      form: {},
      dialogKey: '',
      dialogFormVisible: false
    }
  },
  validations () {
    if (this.mivbStibMobib) {
      return {
        form: {
          mobibCardNumber: {
            required
          }
        }
      }
    } else if (this.veloSeason) {
      return {
        form: {
          startDate: {
            required
          },
          accountId: {
            required
          }
        }
      }
    } else if (this.enecoCard) {
      return {
        form: {
          cardNumber: {
            required
          }
        }
      }
    }
    return {
      form: {}
    }
  },
  computed: {
    mivbStibMobib () {
      return (
        this.order &&
        this.order.action === 'PURCHASE_MOBIB_CARD' &&
        this.order.mrpOspId === 'MivbStib'
      )
    },
    veloSeason () {
      return (
        this.order &&
        this.order.action === 'ACTIVATE_SEASON_TICKET' &&
        this.order.mrpOspId === 'Velo'
      )
    },
    enecoCard () {
      return (
        this.order &&
        this.order.action === 'PURCHASE_CHARGING_CARD' &&
        this.order.mrpOspId === 'Eneco'
      )
    }
  },
  methods: {
    show (order) {
      this.order = order
      this.form.orderUuid = order.orderUuid
      this.dialogKey = this.$key()
      if (!this.mivbStibMobib && !this.veloSeason && !this.enecoCard) {
        return this.handleConfirm()
      }
      this.$v.form.$reset()
      this.dialogFormVisible = true
    },
    close () {
      this.dialogFormVisible = false
      this.order = null
      this.form = {}
      this.$v.form.$reset()
    },
    handleConfirm () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return true
      }
      this.$emit('confirm', this.form)
      this.close()
    }
  }
}
</script>

<style scoped></style>
