<template>
  <el-dialog
    :title="$t('backoffice.orders.general_orders.cancel.title')"
    :visible.sync="dialogFormVisible"
    @close="close"
  >
    <base-section id="pluginConfirmTop" withBorder>
      <el-form :key="dialogKey"  label-position="top" :inline="true">
        <base-input
          :value="plugin.userName"
          name="userName"
          :disabled="true"
          auto-complete="off"
          :label="$t('general.label.user')"
        ></base-input>
        <base-input
          :value="plugin.serviceResourceOspId"
          name="Service"
          :disabled="true"
          auto-complete="off"
          :label="$t('general.label.service')"
        ></base-input>
      </el-form>
      <div class="el-message-box__text --padding-top">
        <span>{{$t('general.cancel_plugin.info')}}</span>
      </div>
    </base-section>
    <span slot="footer" class="dialog-footer">
      <base-button :label="$t('general.cancel')" @click="close"></base-button>

      <base-button
        :loading="loading"
        type="danger"
        :label="$t('general.button_label.save_changes')"
        @click="handleConfirm"
      ></base-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  name: 'GeneralPluginCancel',

  data () {
    return {
      plugin: {
        userName: '',
        serviceResourceOspId: ''
      },
      loading: false,
      dialogKey: '',
      dialogFormVisible: false,
      purchaseUuid: ''
    }
  },
  methods: {
    show (plugin) {
      this.plugin = plugin.data
      this.purchaseUuid = plugin.purchaseUuid
      this.dialogKey = this.$key()
      this.dialogFormVisible = true
    },

    close () {
      this.dialogFormVisible = false
    },

    handleConfirm () {
      this.$emit('confirm', this.purchaseUuid)
      this.close()
    }
  }
}
</script>

<style scoped>
</style>
