import PluginServiceRepository from '@/app/repositories/PluginServiceRepository'
import { DATE_FORMAT, DATE_TIME_ISO } from '@/app/helpers'
import { DateTime } from 'luxon'
import { orderBy, debounce } from 'lodash'
import TableLoader from '@/mixins/TableLoader'

const pluginService = new PluginServiceRepository()
const defaultSort = { order: 'descending', prop: 'createdOn' }

export default {
  name: 'Plugins',
  mixins: [TableLoader],
  props: {
    totalPages: {
      type: Number,
      default: 0
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          page: 0,
          size: 25,
          sort: {
            sortOrders: [
              {
                direction: 'DESCENDING',
                property: 'createdOn'
              }
            ]
          }
        }
      }
    }
  },
  data () {
    return {
      defaultSort,
      sortOrders: ['ascending', 'descending'],
      pluginsData: [],
      isEmailModalVisible: false,
      emailData: '',
      userSearch: '',
      purchaseCreatedOnFrom: null,
      purchaseCreatedOnTo: null,
      status: 'OPEN'
    }
  },
  watch: {
    'pagination.page': debounce(function () {
      this.fetchPlugins()
    }, 200),
    userSearch: debounce(function () {
      this.fetchPlugins()
    }, 400),
    purchaseCreatedOnFrom (v) {
      this.fetchPlugins()
    },
    purchaseCreatedOnTo (v) {
      this.fetchPlugins()
    },
    status: function () {
      this.fetchPlugins()
    }
  },
  computed: {
    tableData () {
      const order = this.pagination.sort.order === 'ascending' ? 'asc' : 'desc'
      return orderBy(this.pluginsData, this.pagination.sort.prop, [order])
    },
    filter () {
      return {
        status: this.status,
        pagination: this.pagination,
        purchaseCreatedOnFrom: this.formatDate(this.purchaseCreatedOnFrom, DATE_TIME_ISO),
        purchaseCreatedOnTo: this.formatDate(this.purchaseCreatedOnTo, DATE_TIME_ISO),
        userSearch: this.userSearch
      }
    },
    getEmailData () {
      return this.emailData
    }
  },
  methods: {
    formatDate (date, format = DATE_FORMAT) {
      return date ? DateTime.fromISO(date).toFormat(format) : date
    },
    fetchPlugins () {
      this.filter.pagination.sort = !this.filter.pagination.sort.sortOrders
        ? {
          sortOrders: [
            {
              direction: 'DESCENDING',
              property: 'createdOn'
            }
          ]
        }
        : this.filter.pagination.sort
      this.TableLoader_show()
      pluginService.getPlugins(this.filter, {
        onSuccess: res => {
          this.pluginsData = res.content
          this.$emit('update:totalPages', res.totalPages)
        },
        onFinally: () => {
          this.TableLoader_hide()
        }
      })
    },
    getEmail ({ purchaseUuid }) {
      this.TableLoader_show()
      pluginService.getEmail(
        { purchaseUuid },
        {
          onSuccess: response => {
            if (response) {
              this.emailData = response
              this.isEmailModalVisible = true
            }
          },
          onFinally: () => {
            this.TableLoader_hide()
          }
        }
      )
    },
    getEmailWithoutModal ({ purchaseUuid }) {
      this.TableLoader_show()
      pluginService.getEmail(
        { purchaseUuid },
        {
          onSuccess: response => {
            if (response) {
              this.emailData = response
            }
          },
          onFinally: () => {
            this.TableLoader_hide()
          }
        }
      )
    },
    showUserDetails ({ data }) {
      const loading = this.TableLoader_show()
      this.$router.push(
        {
          name: 'AppUserDetailView',
          params: {
            userOspId: data.userOspId
          },
          query: {
            corporateAccountOspId: data.corporateAccountOspId
          }
        },
        () => this.$sleep(50).then(loading.close),
        () => this.$sleep(50).then(loading.close)
      )
    },
    closeEmailModal () {
      this.isEmailModalVisible = false
      this.emailData = ''
    },
    sortChange (sort) {
      this.pagination.sort.sortOrders = [
        {
          direction: sort.order.toUpperCase(),
          property: sort.prop
        }
      ]
      return this.fetchPlugins()
    }
  },
  mounted () {
    this.fetchPlugins()
  }
}
