<template>
  <el-dialog
    :title="$t('backoffice.orders.general_orders.cancel.title')"
    :visible.sync="dialogFormVisible"
    @close="close">
    <el-form
      v-if="order"
      :key="dialogKey"
      :model="form"
      label-position='top'
    >
      <base-input
        type="textarea"
        :label="$t('backoffice.orders.general_orders.cancel.message.label')"
        maxlength="140"
        :autosize="{minRows: 5, maxRows: 8}"
        v-model.trim="form.message"
        prop="message"
        name="message"
        :show-word-limit="true"
        :placeholder="$t('user_management.view_app_users_page.add_expense.input_description.placeholder', {number: 140})"
        :max-width="400"
        :validations="$v.form.message"
        @submit="handleConfirm"
      />
    </el-form>
    <span slot="footer" class="dialog-footer">
        <base-button
          :label="$t('general.cancel')"
          @click="close"
        ></base-button>
        <base-button
          :loading="loading"
          type="primary"
          :label="$t('general.button_label.save_changes')"
          @click="handleConfirm"
        ></base-button>
  </span>
  </el-dialog>

</template>

<script>
// import { required } from 'vuelidate/lib/validators'

export default {
  name: 'GeneralOrderCancel',
  data () {
    return {
      order: null,
      loading: false,
      form: {},
      dialogKey: '',
      dialogFormVisible: false
    }
  },
  validations () {
    return {
      form: {}
    }
  },
  methods: {
    show (order) {
      this.order = order
      this.form.orderUuid = order.orderUuid
      this.form.message = ''
      this.dialogKey = this.$key()
      this.dialogFormVisible = true
    },
    close () {
      this.dialogFormVisible = false
      this.order = null
      this.form = {}
    },
    handleConfirm () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return true
      }
      this.$emit('confirm', this.form)
      this.close()
    }
  }
}
</script>

<style scoped>

</style>
