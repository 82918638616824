<template>
  <el-dialog
    :title="$t('backoffice.orders.general_orders.confirmation.title')"
    :visible.sync="dialogFormVisible"
    class="confirmation-plugin-dialog"
    @close="close"
  >
    <div class="email-info">
      <base-section>
        <p>
          <span class="text-muted">
            {{ $t('backoffice.orders.general_orders.emailModal.from') }}:&nbsp;
          </span>
          <span v-html="emailData.from"></span>
        </p>
        <p v-if="emailData.fromName">
          <span class="text-muted">
            {{ $t('backoffice.orders.general_orders.emailModal.senderName') }}:&nbsp;
          </span>
          <span v-html="emailData.fromName"></span>
        </p>
      </base-section>
      <base-section>
        <p>
          <span class="text-muted">
            {{ $t('backoffice.orders.general_orders.emailModal.subject') }}:&nbsp;
          </span>
          <span class="text-bold" v-html="emailData.subject"></span>
        </p>
      </base-section>
      <!--    <div>Subject: <span v-html="emailData.subject"></span></div>-->
      <base-section>
        <div v-html="emailData.content" />
      </base-section>
      <base-section v-if="attachments && attachments.length">
        <p>
          <span class="text-muted">
            {{ $t('backoffice.orders.general_orders.emailModal.attachments') }}:&nbsp;
          </span>
        </p>
        <ul>
          <li v-for="(attachment, index) in attachments" :key="index">
            <a
              :href="`data:${attachment.mimeType};base64,${attachment.fileContentBase64}`"
              :download="attachment.fileName"
              >{{ attachment.fileName }}</a
            >
          </li>
        </ul>
      </base-section>
    </div>

    <el-divider direction="vertical"></el-divider>

    <div class="confirmation-info">
      <base-section id="pluginConfirmTop">
        <el-form label-position="top" :inline="true" :key="dialogKey">
          <base-input
            :value="getPlugin.userName"
            name="userName"
            :disabled="true"
            auto-complete="off"
            :label="$t('general.label.user')"
          />

          <base-input
            :value="getPlugin.serviceResourceOspId"
            name="Service"
            :disabled="true"
            auto-complete="off"
            :label="$t('general.label.service')"
          />
        </el-form>
      </base-section>

      <el-form id="pluginConfirmBottom" :key="dialogKeyB">
        <div v-if="showOriginalPrice" class="original-price">
          <base-input
            v-model="form.originalCurrency"
            name="originalCurrency"
            :label="$t('general.label.original_currency')"
          />
          <base-currency-input
            v-model="form.originalPriceVatIncl"
            :validations="$v.form.originalPriceVatIncl"
            :label="$t('general.label.original_price_vat')"
            :currency="{
              suffix: null,
              prefix: null
            }"
          />
        </div>

        <base-currency-input
          ref="amountOfExpenses"
          v-model="form.priceVatIncl"
          :label="$t('general.label.amount_of_expense')"
          :validations="$v.form.priceVatIncl"
        />
        <base-input
          type="textarea"
          :label="$t('general.input_label.description')"
          prop="description"
          name="description"
          v-model.trim="form.description"
          :rows="2"
        />
        <base-select
          v-model="form.purchaseType"
          :items="typesPurchase"
          :label="$t('general.label.type_purchase')"
        />

        <base-checkbox
          v-model="form.overrideLimits"
          :label="$t('general.label.expenses_checkbox')"
        ></base-checkbox>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <base-button :disabled="loading" :label="$t('general.cancel')" @click="close" />

      <base-button
        type="primary"
        :loading="loading"
        :label="$t('backoffice.plugins.confirm_save_button')"
        @click="handleConfirm"
      />
      <base-button
        type="danger"
        :disabled="loading"
        :label="$t('general.label.delete')"
        @click.stop="handleDelete"
      ></base-button>
    </span>
  </el-dialog>
</template>

<script>
import { get, isNumber } from 'lodash'

export default {
  name: 'GeneralPluginConfirmation',

  props: {
    emailData: {
      type: [Object, String, null],
      default: () => {}
    }
  },

  data () {
    return {
      origin: null,
      plugin: {
        userName: '',
        serviceResourceOspId: ''
      },
      loading: false,
      form: {
        priceVatIncl: 0,
        purchaseType: 'EXTRA_COST',
        purchaseUuid: '',
        overrideLimits: false,
        originalCurrency: null,
        originalPriceVatIncl: null
      },
      dialogKey: '',
      dialogKeyB: '',
      dialogFormVisible: false,
      typesPurchase: [
        {
          value: 'STANDARD'
        },
        {
          value: 'EXTRA_COST'
        }
      ]
    }
  },

  validations () {
    return {
      form: {
        priceVatIncl: {
          required: function (value) {
            return value !== 0
          }
        },
        originalPriceVatIncl: {
          required: function (value) {
            return this.showOriginalPrice ? isNumber(value) : true
          }
        }
      }
    }
  },

  computed: {
    getPlugin () {
      return this.plugin
    },
    getForm () {
      return this.form
    },
    attachments () {
      return get(this.emailData, 'attachments', [])
    },
    showOriginalPrice () {
      return !!this.getPlugin.originalCurrency && isNumber(this.getPlugin.originalPriceVatIncl)
    }
  },

  methods: {
    show (plugin) {
      this.dialogFormVisible = true
      this.$v.form.$reset()
      this.origin = plugin
      this.plugin = plugin.data
      this.form = {
        priceVatIncl: plugin.data.priceVatIncl,
        purchaseType: plugin.data.purchaseType,
        purchaseUuid: plugin.purchaseUuid,
        description: plugin.data.description,
        overrideLimits: false,
        originalCurrency: plugin.data.originalCurrency,
        originalPriceVatIncl: plugin.data.originalPriceVatIncl
      }
      this.dialogKey = plugin.purchaseUuid
      this.dialogKeyB = this.$key()
      this.$nextTick(() => {
        this.$refs.amountOfExpenses.$refs.input.$el.focus()
      })
    },

    close () {
      this.dialogFormVisible = false
      this.$emit('close')
    },

    handleConfirm () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return true
      }

      this.$emit('confirm', this.form)
    },

    handleDelete () {
      this.$emit('delete', this.origin)
    },

    setLoading (v) {
      this.loading = v
    }
  }
}
</script>

<style lang="scss" scoped>
.original-price {
  display: flex;
  :deep(.el-form-item) {
    margin-right: 10px;
  }
  :deep(.el-input) {
    width: 100% !important;
  }
  :deep(label) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
  }
}
</style>
