import Model from './Model'
import OrderRepository from '@/app/repositories/OrderRepository'
import { DATE_FORMAT, trans } from '@/app/helpers'
import { DateTime } from 'luxon'
// import { get } from 'lodash'

// action: "PURCHASE_MOBIB_CARD"
// corporateAccountExtId: "Junk_customer"
// eventOspId: "MivbStib.EVENT.4f613709-bf2a-4a24-8216-086da78d43e6"
// mobib: null
// mrpOspId: "MivbStib"
// orderFulfilmentUrls: []
// orderStatus: "REQUESTED"
// orderUuid: "22274ace-5713-49c9-8939-eb19a4af8ab8"
// partnerExtId: "Junk_customer"
// processBefore: "2020-12-23T19:19:20+0000"
// processedOn: null
// processingUserOspId: null
// product: {defaultValue: "Mobib kaart", defaultValueHtml: null,…}
// productCode: null
// purchaseEventOspId: "MivbStib.EVENT.4f613709-bf2a-4a24-8216-086da78d43e6"
// requestedOn: "2020-12-22T19:19:20+0000"
// serviceResourceOspId: "MivbStib.RESOURCE.SERVICE_RESOURCE.MOBIB"
// startDate: null
// userBirthDate: "1983-02-10"
// userExtId: "Junk_customer"
// userFirstName: "Junk_customer"
// userHomeCity: "Brussel"
// userHomeStreet: "Rue du Grand Hospice "
// userHomeStreetNumber: "2"
// userHomeZipCode: "1000"
// userLanguage: "en"
// userLastName: "Junk_customer"
// userOspId: "Junk_customer.CORPORATE_ACCOUNT.Junk_customer.USER.Junk_customer"

class Order extends Model {
  #repository = null

  constructor (object = {}) {
    super(object)
    this.#repository = new OrderRepository()
  }

  get userName () {
    return `${this.userFirstName} ${this.userLastName}`
  }

  get service () {
    return trans(this.product)
  }

  get requestedOnDate () {
    return DateTime.fromISO(this.requestedOn).toFormat(DATE_FORMAT)
  }

  // get mobibCardNumber () {
  //   return get(this.mobib, 'mobibCardNumber', '')
  // }

  get corporateAccountOspId () {
    return `${this.partnerExtId}.CORPORATE_ACCOUNT.${this.corporateAccountExtId}`
  }
}

export default Order
