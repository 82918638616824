import api from '@/api'
import Repository from './Repository'
import Order from '@/app/models/Order'

class OrderRepository extends Repository {
  constructor () {
    super(Order)
  }

  getOpen (options) {
    const optionsData = this.addWrapper(options)
    return api.order.getOpen(optionsData)
  }

  getHandled (form, options) {
    const optionsData = this.addWrapperWithPaginator(options)
    return api.order.getHandled(form, optionsData)
  }

  confirm (form, options) {
    return api.order.confirm(form, options)
  }

  cancel (form, options) {
    return api.order.cancel(form, options)
  }
}

export default OrderRepository
